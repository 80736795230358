html {
  scroll-behavior: smooth;
}

.textEffect {
  font-size: 5rem;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100 !important;
}

.textEffect2 {
  font-size: 3.8rem;
  position: absolute;
  top: 68%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100 !important;
}

@media screen and (max-width: 674px) {
  .textEffect {
    font-size: 3.8rem;
  }
  .textEffect2 {
    font-size: 3rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress {
  display: none;
}

h1 {
  font-size: 2.2rem;
}

h6 {
  font-size: 1.1rem;
}

.container-fluid {
  padding-left: 4px;
  padding-right: 4px;
}
